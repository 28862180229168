import React, { useEffect } from 'react';
import {
  $city,
  $currency,
  $deliveryZonesAndMapLayerZones,
  DeliveryZoneAndMapLayerZone,
} from 'domains/cartography';
import { Map, ZoneBottomSheet, ZoneModal } from './components';
import { Polygon } from '@react-google-maps/api';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import style from './style.module.css';
import { useMediaQuery } from 'ui-kit/hooks';
import { FEButton, FESpinner } from 'components';
import {
  IMG_IC_24_BONUS,
  IMG_IC_24_CARD,
  IMG_IC_24_CASH,
  IMG_IC_24_NEWCARD,
  IMG_IC_24_SBERPAY,
} from 'ui-kit/images';
import { PaymentType } from 'models';
import { IMG_PLACEHOLDER_PHONE_ORDER } from 'ui-kit/images';
import { LOYALTY_PROGRAM_LINK } from 'const';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { $features } from 'domains/features';

const DeliveryAndPaymentsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const is1024 = useMediaQuery(`(min-width: 1024px)`);

  const features = useUnit($features);

  const showLoyaltyProgram = features.includes('LOYALTY_PROGRAM');

  const paymentTypesObj: Record<
    Exclude<PaymentType, 'SAVED' | 'SBP'>,
    { label: string; caption: React.ReactNode; image: string }
  > = {
    BONUS: {
      label: t('deliveryAndPayments.paymentTypes.BONUS.label'),
      caption: (
        <p className={clsx('text-2', style.paymentTypesListItemCaption)}>
          {t('deliveryAndPayments.paymentTypes.BONUS.caption')}{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href={LOYALTY_PROGRAM_LINK}
            className={clsx('text-2', style.link)}
          >
            {t('deliveryAndPayments.paymentTypes.BONUS.link')}
          </a>
        </p>
      ),
      image: IMG_IC_24_BONUS,
    },
    CARD: {
      image: IMG_IC_24_NEWCARD,
      label: t('deliveryAndPayments.paymentTypes.CARD.label'),
      caption: (
        <p className={clsx('text-2', style.paymentTypesListItemCaption)}>
          {t('deliveryAndPayments.paymentTypes.CARD.caption')}
        </p>
      ),
    },
    CASH: {
      image: IMG_IC_24_CASH,
      label: t('deliveryAndPayments.paymentTypes.CASH.label'),
      caption: (
        <p className={clsx('text-2', style.paymentTypesListItemCaption)}>
          {t('deliveryAndPayments.paymentTypes.CASH.caption')}
        </p>
      ),
    },
    TERMINAL: {
      image: IMG_IC_24_CARD,
      label: t('deliveryAndPayments.paymentTypes.TERMINAL.label'),
      caption: (
        <p className={clsx('text-2', style.paymentTypesListItemCaption)}>
          {t('deliveryAndPayments.paymentTypes.TERMINAL.caption')}
        </p>
      ),
    },
    SBERPAY: {
      image: IMG_IC_24_SBERPAY,
      label: t('deliveryAndPayments.paymentTypes.SBERPAY.label'),
      caption: (
        <p className={clsx('text-2', style.paymentTypesListItemCaption)}>
          {t('deliveryAndPayments.paymentTypes.SBERPAY.caption')}
        </p>
      ),
    },
  };

  const [
    currentDeliveryZoneAndMapLayerZone,
    setCurrentDeliveryZoneAndMapLayerZone,
  ] = React.useState<DeliveryZoneAndMapLayerZone | null>(null);

  const [deliveryZonesAndMapLayerZones, city, currency] = useUnit([
    $deliveryZonesAndMapLayerZones,
    $city,
    $currency,
  ]);

  useEffect(
    () =>
      setCurrentDeliveryZoneAndMapLayerZone(deliveryZonesAndMapLayerZones[0]),
    [deliveryZonesAndMapLayerZones],
  );

  return (
    <>
      {city && (
        <Helmet>
          <title>{`Доставка и способы оплаты | Условия и время доставки | СушиВесла ${city.name}`}</title>
          <title>
            {t('meta.deliveryAndPayments.title', { city: city.name })}
          </title>
          <meta
            name='description'
            content={t('meta.deliveryAndPayments.metaDescription', {
              city: city.name,
            })}
          />
        </Helmet>
      )}
      <div className={style.root}>
        {city ? (
          <>
            <div className={style.breadCrumbs}>
              <span
                className='text-2'
                onClick={() => {
                  navigate('/');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                {t('deliveryAndPayments.home')}
              </span>
              <span className='text-2'>/</span>
              <span className='text-2'> {t('deliveryAndPayments.title')}</span>
            </div>
            <h1 className={clsx(is1024 ? 'heading1' : 'heading2', style.title)}>
              {t('deliveryAndPayments.title')}
            </h1>
            <div className={style.mapContainer}>
              <Map
                onClick={() => {
                  // setCurrentDeliveryZoneAndMapLayerZone(null)
                }}
                className={style.map}
                center={{
                  lat: city.location.latitude,
                  lng: city.location.longitude,
                }}
              >
                {deliveryZonesAndMapLayerZones.length ? (
                  deliveryZonesAndMapLayerZones.map((zone, i) => {
                    const isCurrentZone =
                      currentDeliveryZoneAndMapLayerZone &&
                      zone.mapLayerZone.id ===
                        currentDeliveryZoneAndMapLayerZone.mapLayerZone.id;

                    return (
                      <Polygon
                        key={zone.deliveryZone.id}
                        options={{
                          fillColor: zone.mapLayerZone.color,
                          fillOpacity: isCurrentZone ? 0.65 : 0.3,
                          strokeColor: zone.mapLayerZone.color,
                          strokeOpacity: isCurrentZone ? 0.8 : 0.4,
                          zIndex: 1,
                        }}
                        paths={zone.mapLayerZone.border.map((border) => ({
                          lat: border.latitude,
                          lng: border.longitude,
                        }))}
                        onClick={(e) => {
                          if (
                            zone.mapLayerZone.id !==
                            currentDeliveryZoneAndMapLayerZone?.mapLayerZone.id
                          ) {
                            setCurrentDeliveryZoneAndMapLayerZone(zone);
                          }
                          e.domEvent.stopPropagation();
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </Map>
              {is1024 ? (
                <ZoneModal
                  currency={currency}
                  deliveryZoneAndMapLayerZone={
                    currentDeliveryZoneAndMapLayerZone
                  }
                  open={!!currentDeliveryZoneAndMapLayerZone}
                  onClose={() => {
                    setCurrentDeliveryZoneAndMapLayerZone(null);
                  }}
                />
              ) : (
                <ZoneBottomSheet
                  currency={currency}
                  deliveryZoneAndMapLayerZone={
                    currentDeliveryZoneAndMapLayerZone
                  }
                  open={Boolean(currentDeliveryZoneAndMapLayerZone)}
                  onClose={() => {
                    setCurrentDeliveryZoneAndMapLayerZone(null);
                  }}
                />
              )}
            </div>
            <div className={style.paymentTypes}>
              <div className={style.paymentTypesContainer}>
                <h1
                  className={clsx(
                    is1024 ? 'heading' : 'title',
                    style.paymentTypesTitle,
                  )}
                >
                  {t('deliveryAndPayments.paymentTypes.title')}
                </h1>
                <div className={style.paymentTypesList}>
                  <div className={style.paymentTypesListItems}>
                    {currentDeliveryZoneAndMapLayerZone?.deliveryZone.availablePaymentTypes
                      .filter((paymentType) => paymentType !== 'SAVED')
                      .filter((paymentType) => {
                        if (showLoyaltyProgram) {
                          return true;
                        }
                        return paymentType !== 'BONUS';
                      })
                      .map((paymentType) => {
                        const paymentTypeData =
                          paymentTypesObj[
                            paymentType as Exclude<PaymentType, 'SAVED' | 'SBP'>
                          ];

                        if (paymentTypeData) {
                          const { label, caption, image } = paymentTypeData;

                          return (
                            <div
                              key={paymentType}
                              className={style.paymentTypesListItem}
                            >
                              <div className={style.paymentTypesListItemImage}>
                                <img src={image} alt='' />
                              </div>
                              <div>
                                <p className='headline'>{label}</p>
                                <p
                                  className={clsx(
                                    'text-2',
                                    style.paymentTypesListItemCaption,
                                  )}
                                >
                                  {caption}
                                </p>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                  <FEButton
                    className={clsx(is1024 && style.makeOrder)}
                    onClick={() => {
                      navigate('/');
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                    children={t(
                      'deliveryAndPayments.paymentTypes.makeOrderText',
                    )}
                  />
                </div>
                {is1024 && (
                  <div className={style.phoneImageContainer}>
                    <img
                      src={IMG_PLACEHOLDER_PHONE_ORDER}
                      alt=''
                      className={style.phoneImage}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={style.loaderContainer}>
            <FESpinner size='large' />
          </div>
        )}
      </div>
    </>
  );
};

export default DeliveryAndPaymentsPage;
